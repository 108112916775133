/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { jsx } from "theme-ui"

import { Link } from "gatsby"
import Stack from "./stack"
import Heading from "./heading"
import Box from "./box"
import Button from "./button"
import Icon from "./icon"
import Paragraph from "./paragraph"
import Inline from "./inline"
import Badge from "./badge"
import Text from "./text"

const CourseCard = ({
  title,
  to,
  description,
  duration,
  chapters,
  grade,
  cta,
}) => {
  return (
    <Link
      to={to}
      sx={{
        display: "block",
        height: "100%",
        position: "relative",
        ":hover .course-card-button, :focus .course-card-button": {
          borderColor: "black",
        },
        ":hover .course-card-button *, :focus .course-card-button *": {
          color: "black",
        },
        ":hover .course-card-box, :focus .course-card-box": {
          bg: "primary",
        },
        ":hover .course-card-text, :focus .course-card-text": {
          color: "black",
        },
        ":hover .course-card-badge, :focus .course-card-badge": {
          bg: "black",
          color: "white",
        },
      }}
    >
      <Box
        className="course-card-box"
        bg="black"
        p={[6, 12]}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Stack space={6}>
          <Stack space={3}>
            <Text color="white" className="course-card-text" sans>
              {grade}
            </Text>
            <Heading
              className="course-card-text"
              lang="de"
              hyphens="auto"
              as="h2"
              level={4}
              color="white"
            >
              {title}
            </Heading>
          </Stack>
          <Paragraph
            className="course-card-text"
            size={[2, 2, 3]}
            color="white"
          >
            {description}
          </Paragraph>
        </Stack>
        <Box pt={12}>
          <Stack>
            <Inline>
              <Badge className="course-card-badge">{`${chapters} Kapitel`}</Badge>
              <Badge className="course-card-badge">{`${duration} min`}</Badge>
            </Inline>
            <Button className="course-card-button" color="white" secondary>
              {cta}
            </Button>
          </Stack>
        </Box>
      </Box>
      <Box
        position="absolute"
        bottom={0}
        right={0}
        zIndex="10"
        className="course-card-square"
        bg="primary"
        color="black"
        p={3}
        textAlign="center"
        display="inline-flex"
        alignX="center"
        alignY="center"
      >
        <Icon icon="arrow-right" />
      </Box>
    </Link>
  )
}

export default CourseCard
