/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { useStaticQuery, graphql } from "gatsby"
import { useColorMode, jsx } from "theme-ui"
import Layout from "../../layouts/default"
import Seo from "../../components/seo"

import Box from "../../components/box"
import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import CourseCard from "../../components/course-card"
import Grid from "../../components/grid"
import Inline from "../../components/inline"
import PageTitle from "../../components/page-title"
import Stack from "../../components/stack"
import Text from "../../components/text"

import logoBKM from "../../images/partner-logos/bkm.svg"
import logoBKMWhite from "../../images/partner-logos/bkm-white.svg"
import logoNeustart from "../../images/partner-logos/neustart.svg"
import logoNeustartLight from "../../images/partner-logos/neustart-light.svg"
import logoSozioKultur from "../../images/partner-logos/sozio-kultur.svg"
import logoSozioKulturLight from "../../images/partner-logos/sozio-kultur-light.svg"

const Page = () => {
  const [colorMode] = useColorMode()

  const data = useStaticQuery(graphql`
    query KurseIndexQuery {
      ogImage: file(relativePath: { eq: "og_images/kurse.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="kurse">
      <Seo
        title="Digitale Kurse"
        description="Lust auf Lyrik? Unsere interaktiven Online-Kurse ermöglichen Annäherungen an Hölderlins Sprachkunstwerke. Im Mittelpunkt steht jeweils ein Gedicht von Hölderlin, das aus verschiedenen Perspektiven erschlossen wird."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />

      <Stack>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Für Schulen",
                link: "/fuer-schulen",
              },
              {
                title: "Digitale Kurse",
                link: "/kurse",
              },
            ]}
          />
          <PageTitle>Digitale Kurse</PageTitle>
          <Paragraph dropcap={true}>
            Lust auf Lyrik? Unsere interaktiven Kurse ermöglichen Annäherungen
            an Hölderlins Sprachkunstwerke. Im Mittelpunkt steht jeweils ein
            Gedicht von Hölderlin, das aus verschiedenen Perspektiven
            erschlossen wird. Vom Grundschul- bis ins Erwachsenen-Alter ist für
            Alle etwas dabei!
          </Paragraph>

          <Grid columns={[1, 1, 2]} space={6}>
            <CourseCard
              title="Mit Hölderlin auf Reisen"
              description="Auf seiner ersten größeren Reise hat Hölderlin eine Menge erlebt: Er ist auf einem Pferd geritten, ist mit der Fähre über den Rhein gefahren und war im Theater. In seinem Reisetagebuch hat er alles genau festgehalten. Wir machen uns mit Hölderlin auf den Weg und verfolgen seine Reise in den Tagebucheinträgen und Briefen an seine Mutter."
              grade="Ab 3. und 4. Klasse"
              chapters={2}
              duration={120}
              to="/kurse/hoelderlins-reisen"
              cta="Kurs starten"
            />
            <CourseCard
              title="Die versteckte Ordnung der Gedichte"
              description="Woran erkennt man ein Gedicht? Hat ein Gedicht eine Ordnung? Und aus was wird ein Gedicht eigentlich gemacht? In diesem Kurs lernst du Merkmale, die ein Gedicht auszeichnen und kommst der versteckten Ordnung der Gedichte auf die Schliche."
              grade="Ab 5. und 6. Klasse"
              chapters={2}
              duration={45}
              to="/kurse/versteckte-ordnung"
              cta="Kurs starten"
            />
            <CourseCard
              title="Hölderlin und die Jahreszeiten"
              description="Friedrich Hölderlin liebte die Jahreszeiten. Mehr als 27 Gedichte hat er zum Frühling, Sommer, Herbst und Winter verfasst. Eines davon nehmen wir uns in diesem Kurs vor. Wir zerlegen es in seine Einzelteile und sammeln Tricks und Kniffe, um am Ende ein eigenes Jahreszeitengedicht zu entwerfen."
              grade="Ab 5. und 6. Klasse"
              chapters={2}
              duration={90}
              to="/kurse/jahreszeiten"
              cta="Kurs starten"
            />
            <CourseCard
              title="Eine Reiseerinnerung wird zum Gedicht"
              description="Hölderlin war ein Wanderer. Seine längste Fußreise führte ihn bis nach Frankreich. Vieles Gesehene und Erlebte ist dabei in seine Gedichte eingegangen. Anhand seiner Ortsbezüge und Landschaftsbilder näherst du dich Hölderlins Gedicht »Andenken« im Kontext des Reisens und der Reiseliteratur der Zeit um 1800. Du inspizierst die sprachliche Gemachtheit des Gedichts, verfolgst ihre Wirkung im Hören, Gehen und Sprechen und sammelst eigene Bilder dazu."
              grade="Ab 11. und 12. Klasse"
              chapters={2}
              duration={120}
              to="/kurse/reiseerinnerung"
              cta="Kurs starten"
            />
            <CourseCard
              title="Hölderlins Meisterwerk ›Hälfte des Lebens‹"
              description="Hinter Hölderlins Gedicht ›Hälfte des Lebens‹ steckt weit mehr als die Beschreibung einer Midlife-Crises. In der Auseinandersetzung mit den Versen und Strophen, Sätzen und Silben, Vokalen und Konsonanten entdeckst du ein raffiniert gefertigtes lyrisches Meisterwerk."
              grade="Für alle Interessierten"
              chapters={2}
              duration={90}
              to="/kurse/haelfte-des-lebens"
              cta="Kurs starten"
            />
          </Grid>

          <Stack space={3}>
            <Text size={2} sans>
              Gefördert von:
            </Text>
            <Box bg="muted" p={[6, 8]}>
              <Inline space={[12, 24]} alignY="center" alignX="center">
                <img
                  src={colorMode === "light" ? logoBKM : logoBKMWhite}
                  alt="Logo BKM"
                  width="300"
                  height="92"
                  sx={{ width: "auto", height: "72px" }}
                />
                <img
                  src={
                    colorMode === "light"
                      ? logoSozioKulturLight
                      : logoSozioKultur
                  }
                  alt="Logo Bundesverband Sozio Kultur"
                  width="160"
                  height="92"
                  sx={{ width: "auto", height: "72px" }}
                />
                <img
                  src={colorMode === "light" ? logoNeustartLight : logoNeustart}
                  alt="Logo Neustart"
                  width="250"
                  height="92"
                  sx={{ width: "auto", height: "72px" }}
                />
              </Inline>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Page
